import '../styles/globals.css'
import type { AppProps } from 'next/app'
import HeaderLayout from '../components/GlobalLayout'

import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client'

const client = new ApolloClient({
	uri: process.env.NEXT_PUBLIC_GRAPHQL_HOST,
	cache: new InMemoryCache(),
})

function MyApp({ Component, pageProps }: AppProps) {
	return (
		<ApolloProvider client={client}>
			<HeaderLayout>
				<Component {...pageProps} />
			</HeaderLayout>
		</ApolloProvider>
	)
}

export default MyApp
