import Head from 'next/head'
import styles from './HeaderLayout.module.css'

import { appName, appDescription } from '../../utils/constants'

export default function HeaderLayout({
	children,
}: {
	children: React.ReactNode
}) {
	return (
		<>
			<Head>
				<title>{appName}</title>
				<meta property="og:url" content="https://harmonize.mx/" />
				<meta property="og:type" content="website" />
				<meta property="og:title" content={appName} />
				<meta name="description" content={appDescription} />
				<meta property="og:description" content={appDescription} />
				<meta name="twitter:card" content="summary" />
				<meta
					property="og:image"
					content="https://harmonize.mx/MixyMatchyLogo.png"
				/>
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1, maximum-scale=1"
				/>
				<link rel="icon" href="/MixyMatchyLogo.png" />
			</Head>

			<div className={styles.container}>{children}</div>
		</>
	)
}
